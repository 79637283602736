<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
    :row-class="(row, index) => getStatusColor(row.bottles, row.original_bottles)"
  >
    <b-table-column
      v-slot="props"
      field="StockArticle.id"
      label="S/Id"
      cell-class="status"
      numeric
      sortable
    >
      <a
        :href="`/stocks/view/${props.row.id}`"
        title="Ansehen"
        @click.prevent="stockView(props.row.id)"
        ><b-icon icon="discout" class="green" v-if="props.row.specialoffer == 1"></b-icon>{{ $utilities.padLeftWithNull(props.row.id) }}</a
      >
    </b-table-column>

   

    <b-table-column v-slot="props" field="StockArticle.article" label="Wein" sortable>
      <span :class="props.row.fine_wine == 1 ? 'badge-fw' : 'badge-hw'">{{
        props.row.fine_wine == 1 ? "FW" : "HW"
      }}</span>

      <a class="article-link"
        :href="`/stocks/view/${props.row.id}`"
        title="Ansehen"
        @click.prevent="stockView(props.row.id)"
        >{{ props.row.article }}</a
      >
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="StockArticle.consignment_id"
      label="Einl."
      sortable
      numeric
    >
      <router-link
        :to="'/consignments/view/' + props.row.consignment_id"
        :title="props.row.info"
        >{{ props.row.consignment_id }}</router-link
      >
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="StockArticle.bottles"
      label="Fl."
      sortable
      numeric
    >
      {{ props.row.bottles }} von {{ props.row.original_bottles }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="StockArticle.price_vk"
      label="VK-Preis"
      sortable
      numeric
    >
      <span class="smallgrey3 has-text-danger" title="Differenzbesteuert">{{
        props.row.from_private == 1 ? "DS&nbsp;" : ""
      }}</span
      >{{ props.row.price_vk }}€
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="StockArticle.stockplace"
      label="Lager"
      sortable
      numeric
    >
      {{ props.row.stockplace }}
    </b-table-column>

    <b-table-column v-slot="props" field="customer.name" label="Lieferant" sortable>
      {{ $utilities.shortenString(props.row.name, 20, "..") }}
    </b-table-column>

    <b-table-column v-slot="props" field="StockArticle.status" label="Status" sortable>
      {{
        props.row.status_code == "2"
          ? props.row.status + " #" + $utilities.padLeftWithNull(props.row.auction_id, 5)
          : props.row.status
      }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Ans."
      class="iconlink"
      field="link1"
      header-class="iconlink"
    >
      <a
        :href="`/stocks/view/${props.row.id}`"
        title="Ansehen"
        @click.prevent="stockView(props.row.id)"
      >
        <b-icon icon="find_in_page"></b-icon>
      </a>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  data() {
    return {
      data: {
        count: 0,
        perpage: 100,
        data: [],
        page: 1,
        order: {
          field: "",
          direction: "",
        },
      },
      error: "",
      subtitle: "",
      isLoading: true,
      searchphrase: "",
      modal: false,
    };
  },

  created: function () {
    document.querySelector('title').innerHTML = 'Lager | SG Fine Wine'
    //console.log("Modul AuctionsLotsList");
    this.$store.commit("setHeadline", {
      subheadline: "Übersicht",
    });
    this.stockList();
  },
  methods: {
    onPageChange(page) {
      this.data.page = page;
      this.stockList();
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)
      this.data.sortField = field;
      this.data.sortOrder = direction;
      this.data.order = { field, direction };
      this.stockList();
    },
    search: function (searchphrase) {
      this.searchphrase = searchphrase;
      this.stockList();
    },
    stockList: async function (data = null) {
      this.isLoading = true;
      if (data != null) {
        //console.log('Daten übergeben')
        this.data = data;
        this.isLoading = false;
      } else {
        var t = this;
        t.$http
          .post("/stocks", {
            page: t.data.page,
            search: t.$parent.searchphrase,
            order: t.data.order,
          })

          .then(function (resp) {
            t.data = resp.data.data;
            t.isLoading = false;
            return true;
          })
          .catch(function (error) {
            //console.log(error)
            if (
              typeof error.response != "undefined" &&
              typeof error.response.data.message != "undefined"
            ) {
              t.error = error.response.data.message;
            } else {
              t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
            }
          });
      }
    },
    stockView: function (id) {
      this.$router
        .push({
          path: "/stocks/view/" + id,
        })
        .catch((err) => {});
    },
    getStatusColor: function (bottles, original_bottles) {
      if (bottles == 0) {
        return "gray";
      } else if (bottles == original_bottles) {
        return "green";
      } else if (bottles >= 1) {
        return "lightgreen";
      } else {
        return "red";
      }
    },
  },
};
</script>
